import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
// import profile from "../../assets/images/logo/Logo_notext.svg";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import FranchiseLoginCheck from "../FranchiseLoginCheck";
import Config from "../../../Config";
import {
    EyeIcon,
    EyeSlashIcon,
} from "@heroicons/react/24/outline";

function FranchiseSetting(props) {
    const userstatus = useParams();
    const userstatusVal = userstatus.userstatus;


    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [loading, setLoading] = useState(false);
    const [PinCodeLoading, setPinCodeLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [PinCodeValidated, setPinCodeValidated] = useState(false);

    // passwords
    const [typeOldPassword, setTypeOldPassword] = useState(false);
    const [typeNewPassword, setTypeNewPassword] = useState(false);
    const [typeConfPassword, setTypeConfPassword] = useState(false);

    // PinCode
    const [TypeOldPinCode, setTypeOldPinCode] = useState(false);
    const [TypeNewPinCode, setTypeNewPinCode] = useState(false);


    const navigate = useNavigate();

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {


            let password1 = event.target.NewPassword.value;
            let password2 = event.target.ConfirmPassword.value;

            if (password1 != password2) {
                toast.error('New password and Confirm password should be same', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                setLoading(true);
                const data = {
                    UserID: UserID,
                    AccessKey: AccessKey,
                    OldPassword: event.target.OldPassword.value,
                    NewPassword: event.target.NewPassword.value,

                };
                var api_config = {
                    method: 'post',
                    url: Config.base_url + 'Users/ChangePassword',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };



                axios(api_config)
                    .then(function (response) {

                        // console.log(response.data);
                        if (response.data.status_code == 1) {
                            toast.success('Password updated successfully', {
                                position: toast.POSITION.TOP_RIGHT
                            });

                            setTimeout(() => {

                                localStorage.clear();
                                navigate("/franchise/login");
                            }, 2000);
                        } else if (response.data.status_message === "Invalid Access Key.") {
                            navigate("/franchise/login");
                          }
                        else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                        setLoading(false);
                    })
                    .catch(function (error) {

                        toast.error('Network Error..', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            }
        }

        setValidated(true);
    };
    const handlePinCode = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {


            setPinCodeLoading(true);
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                OldPin: event.target.OldPin.value,
                NewPin: event.target.NewPin.value,

            };

            // console.log(data)
            var api_config = {
                method: 'post',
                url: Config.base_url + 'Users/ChangePinCode',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {

                    // console.log(response.data);
                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setPinCodeLoading(false);
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    } else if (response.data.status_message === "Invalid Access Key.") {
                        navigate("/franchise/login");
                      }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                })
                .catch(function (error) {

                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setPinCodeValidated(true);
    };



    return (
        <>
            <FranchiseLoginCheck />
            <ToastContainer />

            {/* <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isUpdated ?
                            <>
                                <div className="text-center">
                                    <img src={profile} style={{ maxWidth: "300px" }} />
                                    <p>Photo Updated Successfully</p>
                                </div>

                            </>
                            :
                            <>
                                <div className="upload-photo-wrap">
                                    {
                                        imageSelected ?
                                            <Cropper

                                                zoomTo={0.5}
                                                aspectRatio={8 / 8}
                                                preview=".img-preview"
                                                src={image}
                                                dragMode="move"
                                                viewMode={2}
                                                minCropBoxHeight={10}
                                                minCropBoxWidth={10}
                                                background={false}
                                                responsive={true}
                                                autoCropArea={1}
                                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                onInitialized={(instance) => {
                                                    setCropper(instance);
                                                }}
                                                guides={true}
                                            />
                                            :
                                            <div className="choose-photo">
                                                <label htmlFor="ChoosePhoto">
                                                    <i className="material-symbols-outlined">upload</i>
                                                    <span>Browse Photo</span>
                                                    <input id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                                                </label>

                                            </div>

                                    }


                                </div>
                            </>

                    }

                </Modal.Body>

                {renderButtons()}

            </Modal> */}
            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Security
                        </div>
                    </div>
                </div>


                <div className="card card-table mt-4">
                    <div className="card-inner">
                        <div className="form_default p-4">
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className="row align-items-center gy-3">
                                    <div className="col-md-3">
                                        <div className="icon-form-group">
                                            <label>Current Password</label>
                                            <div className="icon-input">
                                                <input placeholder="Enter Password"
                                                    type={typeOldPassword ? "text" : "password"} className="form-control" name="OldPassword" required />
                                                <div className="icon"
                                                    onClick={(e) => {
                                                        setTypeOldPassword(!typeOldPassword);
                                                    }}
                                                >
                                                    {typeOldPassword ? <EyeSlashIcon /> : <EyeIcon />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="icon-form-group">
                                            <label>New Password</label>
                                            <div className="icon-input">
                                                <input placeholder="Enter New Password"
                                                    type={typeNewPassword ? "text" : "password"} className="form-control" name="NewPassword" required />
                                                <div className="icon"
                                                    onClick={(e) => {
                                                        setTypeNewPassword(!typeNewPassword);
                                                    }}
                                                >
                                                    {typeNewPassword ? <EyeSlashIcon /> : <EyeIcon />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="icon-form-group">
                                            <label>Confirm Password</label>
                                            <div className="icon-input">
                                                <input placeholder="Confirm Password"
                                                    type={typeConfPassword ? "text" : "password"} className="form-control" name="ConfirmPassword" required />
                                                <div className="icon"
                                                    onClick={(e) => {
                                                        setTypeConfPassword(!typeConfPassword);
                                                    }}
                                                >
                                                    {typeConfPassword ? <EyeSlashIcon /> : <EyeIcon />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 text-end">
                                        <button disabled={loading} className="button button-primary button-smlg input-height input-radius">Update Password {loading ? '...' : ''}</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div >
                <div className="card card-table mt-4">
                    <div className="card-inner">
                        <div className="form_default p-4">
                            <Form noValidate validated={PinCodeValidated} onSubmit={handlePinCode}>
                                <div className="row align-items-center gy-3">
                                    <div className="col-md-3">
                                        <div className="icon-form-group">
                                            <label>Current Transaction Pin</label>
                                            <div className="icon-input">
                                                <input placeholder="Enter Transaction Pin"
                                                    type={TypeOldPinCode ? "text" : "password"} maxLength={6} className="form-control" name="OldPin" required />
                                                <div className="icon"
                                                    onClick={(e) => {
                                                        setTypeOldPinCode(!TypeOldPinCode);
                                                    }}
                                                >
                                                    {TypeOldPinCode ? <EyeSlashIcon /> : <EyeIcon />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="icon-form-group">
                                            <label>New Transaction Pin</label>
                                            <div className="icon-input">
                                                <input placeholder="Enter Transaction Pin"
                                                    type={TypeNewPinCode ? "text" : "password"} className="form-control" name="NewPin" required />
                                                <div className="icon"
                                                    onClick={(e) => {
                                                        setTypeNewPinCode(!TypeNewPinCode);
                                                    }}
                                                >
                                                    {TypeNewPinCode ? <EyeSlashIcon /> : <EyeIcon />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 offset-md-3 text-end">
                                        <button disabled={PinCodeLoading} className="button button-primary button-smlg input-height input-radius">Update Transaction Pin {PinCodeLoading ? '...' : ''}</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div >
            </div >

        </>
    );
}

export default FranchiseSetting