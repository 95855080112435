import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Config from '../../Config';
import axios from 'axios';
import { CheckCircleIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import { ToastContainer, toast } from 'react-toastify';
import FranchiseLoginCheck from './FranchiseLoginCheck';

const FranchisePurchasePackage = () => {



  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));


  const [SelectedPackage, setSelectedPackage] = useState(null);
  const [UserData, setUserData] = useState('');
  const [VerifyLoading, setVerifyLoading] = useState(false);
  const [UserVerify, setUserVerify] = useState(null);
  const [Verified, setVerified] = useState(null);
  const [SelectActive, setSelectActive] = useState('Meat');

  const VerifyUser = () => {
    if (UserVerify != null) {

      setVerifyLoading(true);

      const data = {
        Email_Address: UserVerify,
        AccessKey: AccessKey,
        UserID: UserID,
        Member_Type: 'Admin',
      };
      // console.log("data", data)
      var api_config = {
        method: 'post',
        url: Config.base_url + 'Users/GetUserInfo',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(api_config)
        .then(function (response) {
          // console.log("response", response.data);
          if (response.data.status_code == 1) {
            setUserData(response.data);
            setVerified(true);
            setVerifyLoading(false);
            toast.success(response.data.KYCStatus, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          else if (response.data.status_message === "Invalid Access Key.") {
            navigate("/franchise/login");
          }
          else {
            setVerified(false);
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
            setVerifyLoading(false);
          }

        })
        .catch(function (error) {
          // console.log(error);
        });

    }
  }

  const [ListofPackages, setListofPackages] = useState([]);
  const [LoginUserData, setLoginUserData] = useState('');


  const [PicCode, setPicCode] = useState('');

  // loading states
  const [loading, setLoading] = useState(false);
  const [ButtonLoading, setButtonLoading] = useState(false);
  let navigate = useNavigate();


  useEffect(() => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Member_Type: 'Admin'
    };
    // console.log(data);
    var api_config = {
      method: 'post',
      url: Config.base_url + 'Package/GetAllPackages',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(api_config)
      .then(function (response) {
        // console.log("GetAllPackages", response.data);
        if (response.data.status_code == 1) {
          setListofPackages(response.data.ListofPackages);
        }
        else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key." ||
          response.data.status_code === 0 && response.data.status_message === "Invalid Request Parameters") {

          navigate("/franchise/login")

        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

      })
      .catch(function (error) {

      })

  }, []);

  useEffect(() => {


    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    // console.log("data data", data);

    var api_config = {
      method: "post",
      url: Config.base_url + "Users/GetFranchiseDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setLoginUserData(response.data);
          setLoading(false);
        } else if (response.data.status_code === 0 || response.data.status_code === 2) {
          setLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });

        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/franchise/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [loading]);
  const handleSubmit = () => {
    setButtonLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Email_Address: UserData.Email_Address,
      PinCode: PicCode,
      PackageID: SelectedPackage,
    };
    // console.log(data);
    var api_config = {
      method: 'post',
      url: Config.base_url + 'Users/PurchasePackageDownline',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(api_config)
      .then(function (response) {
        // console.log("PurchasePackageDownline", response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setButtonLoading(false);
          setTimeout(() => {
            navigate("/franchise/purchase-package-list")
          }, 3000)

        }
        else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key." ||
          response.data.status_code === 0 && response.data.status_message === "Invalid Request Parameters") {

          navigate("/franchise/login")

        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setButtonLoading(false);
        }

      })
      .catch(function (error) {

      })

  }

  return (
    <>
      <ToastContainer />
      <FranchiseLoginCheck />
      <div className="admin-content">
        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Purchase Package
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="button-row justify-content-end">
              <NavLink to="/franchise/dashboard">
                <button type="button" className="button button-outline-bs">
                  Back
                </button>
              </NavLink>
              <NavLink to="/franchise/purchase-package-list">
                <button type="button" className="button button-primary">
                  Purchased Packages
                </button>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="dash-bg-card mt-4">

          <div className={`ribbon primary`} style={{ margin: "-37px 0px 20px -40px" }}>
            <span className="ribbon-text">
              Available Balance {LoginUserData.BalanceAvailable}
            </span>
            <div className="ribbon-corner"></div>
          </div>


          <div className='row align-items-end gy-3'>
            <div className="col-md-4">
              <div className="icon-form-group">
                <label>Enter Username/Email</label>
                <div className="icon-input m-0 w-100">
                  <input type="text"
                    value={UserVerify}
                    onChange={(e) => {
                      setUserVerify(e.target.value);
                      setVerified(false);
                    }}
                    placeholder="Enter Username/Email"
                    autoComplete="off"
                  />
                  <div className="icon">
                    {
                      Verified ?
                        <CheckCircleIcon />
                        :
                        ''
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <button
                onClick={VerifyUser}
                className="button button-outline-bs button-icon-bs input-height" style={{ borderRadius: '12px' }} type="button"
                disabled={Verified}
              >
                <span>{VerifyLoading ? 'Loading..' : 'Validate'}</span>
                <ShieldCheckIcon />
              </button>
            </div>
            <div className="col-md-3">
            </div>
            <div className="col-md-3">
              <div className="icon-form-group">
                <label>Name</label>
                <div className="icon-input">
                  <input type="text" value={UserData.FullName} readOnly disabled />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="icon-form-group">
                <label>Email Address</label>
                <div className="icon-input">
                  <input type="text" value={UserData.Email_Address} readOnly disabled />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="icon-form-group">
                <label>KYC Status</label>
                <div className="icon-input">
                  <input type="text" value={UserData.KYCStatus} readOnly disabled />
                </div>
              </div>
            </div>
          </div>


          {
            (Verified && UserData.KYCStatus == 'Verified') ?
              <>

                {/* <div className="text-center">
                  <ul className="menu-option mt-5 m-auto">
                    <li>
                      <a className={`nav-link ${SelectActive == "Meat" ? "active" : ""}`} onClick={(e) => setSelectActive("Meat", e)} type="button">Meat Trade Packages</a>
                    </li>
                    <li>
                      <a className={`nav-link ${SelectActive == "Product" ? "active" : ""}`} onClick={(e) => setSelectActive("Product", e)} type="button">Mobile Trade Packages</a>
                    </li>
                  </ul>
                </div> */}
                {/* <li
                  key={index}
                  style={{ cursor: "pointer" }}
                  className={`${SelectedPackage == item.ID ? "active" : ""}`}
                  onClick={(e) => {
                    if (LoginUserData.BalanceAvailable >= item.Amount) {
                      setSelectedPackage(item.ID);
                    }
                  }}

                >
                  <div className="package-card">
                    <div className="image">
                      <img src={Config.root + "Images/Packages/" + item.Image} alt="" />
                    </div>
                    <div className="content">
                      <div className={`ribbon ${item.Name}`}>
                        <span className="ribbon-text">{item.Name} Package</span>
                        <div className="ribbon-corner"></div>
                      </div>
                      <div className="package-header">
                        <h4>{item.TagLine}</h4>
                      </div>
                      <div className="detail">
                        <ul className="list">
                          <li>
                            <h6>{item.Amount} PKR</h6>
                          </li>
                          <li>
                            <span>{item.NoofDays} Days</span>
                          </li>
                        </ul>
                        <svg
                          className={`${item.Name}`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            d="M4.44665 0.840041C5.2567 0.506673 6.12402 0.334568 6.99998 0.333374C7.87332 0.333374 8.73998 0.506707 9.55332 0.840041C10.36 1.17337 11.0933 1.66671 11.7133 2.28671C12.3333 2.90671 12.8266 3.64004 13.16 4.44671C13.4933 5.26004 13.6666 6.12671 13.6666 7.00004C13.6666 8.76671 12.9666 10.4667 11.7133 11.7134C11.095 12.3332 10.3603 12.8249 9.55145 13.1601C8.74261 13.4953 7.87553 13.6675 6.99998 13.6667C6.12402 13.6655 5.2567 13.4934 4.44665 13.16C3.63848 12.8246 2.90446 12.333 2.28665 11.7134C1.66678 11.095 1.17513 10.3604 0.839927 9.55151C0.504721 8.74267 0.332557 7.87559 0.333315 7.00004C0.333315 5.23337 1.03332 3.53337 2.28665 2.28671C2.90665 1.66671 3.63998 1.17337 4.44665 0.840041ZM6.99998 10.3334L8.03998 8.05337L10.3333 7.00004L8.03998 5.96004L6.99998 3.66671L5.95332 5.96004L3.66665 7.00004L5.95332 8.05337L6.99998 10.3334Z"
                            fill="#ff1500"
                          />
                        </svg>
                      </div>
                      <ul className="list">
                        <li>
                          <label>Direct Bonus:</label>
                          <span>{item.DirectBonus}</span>
                        </li>
                        <li>
                          <label>Daily profit:</label>
                          <span>{item.DailyProfitReturn}</span>
                        </li>
                        <li>
                          <label>Monthly Profit:</label>
                          <span>{item.MonthlyProfit}</span>
                        </li>
                        <li>
                          <label>Total Profit Return:</label>
                          <span>{item.TotalProfitReturn}</span>
                        </li>
                        <li>
                          <label>Product deliver:</label>
                          <span>{item.ProductDelivery}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li> */}
                <div className="w-100 text-center">
                  <ul className="responsive-package-list mt-4">
                    {ListofPackages.map((item, index) =>
                      (SelectActive === "Meat" && [1, 5, 6, 7].includes(item.ID)) ||
                        (SelectActive === "Product" && [2, 3].includes(item.ID)) ? (
                        <li
                          key={index}
                          style={{ cursor: "pointer" }}
                          className={`${SelectedPackage == item.ID ? "active" : ""} ${item.ShowPackage ? '' : 'd-none'}`}
                          onClick={(e) => {
                            if (LoginUserData.BalanceAvailable >= item.Amount) {
                              setSelectedPackage(item.ID);
                            }
                          }}

                        >
                          <div className="package-card">
                            <div className="image">
                              <img
                                src={Config.root + "Images/Packages/" + item.Image}
                                alt=""
                              />
                            </div>
                            <div className="content">
                              <div className={`ribbon ${item.Name}`}>
                                <span className="ribbon-text">
                                  {item.Name} Package
                                </span>
                                <div className="ribbon-corner"></div>
                              </div>
                              <div className="package-header">
                                <h4>{item.TagLine}</h4>
                              </div>
                              <div className="detail">
                                <ul className="list">
                                  <li>
                                    <h6>{item.Amount} PKR</h6>
                                  </li>
                                  <li>
                                    <span>{item.NoofDays} Days</span>
                                  </li>
                                </ul>
                                <svg
                                  className={`${item.Name}`}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <path
                                    d="M4.44665 0.840041C5.2567 0.506673 6.12402 0.334568 6.99998 0.333374C7.87332 0.333374 8.73998 0.506707 9.55332 0.840041C10.36 1.17337 11.0933 1.66671 11.7133 2.28671C12.3333 2.90671 12.8266 3.64004 13.16 4.44671C13.4933 5.26004 13.6666 6.12671 13.6666 7.00004C13.6666 8.76671 12.9666 10.4667 11.7133 11.7134C11.095 12.3332 10.3603 12.8249 9.55145 13.1601C8.74261 13.4953 7.87553 13.6675 6.99998 13.6667C6.12402 13.6655 5.2567 13.4934 4.44665 13.16C3.63848 12.8246 2.90446 12.333 2.28665 11.7134C1.66678 11.095 1.17513 10.3604 0.839927 9.55151C0.504721 8.74267 0.332557 7.87559 0.333315 7.00004C0.333315 5.23337 1.03332 3.53337 2.28665 2.28671C2.90665 1.66671 3.63998 1.17337 4.44665 0.840041ZM6.99998 10.3334L8.03998 8.05337L10.3333 7.00004L8.03998 5.96004L6.99998 3.66671L5.95332 5.96004L3.66665 7.00004L5.95332 8.05337L6.99998 10.3334Z"
                                    fill="#ff1500"
                                  />
                                </svg>
                              </div>
                              <ul className="list">
                                <li>
                                  <label>Direct Bonus:</label>
                                  <span>{item.DirectBonus}</span>
                                </li>
                                <li>
                                  <label>Daily profit:</label>
                                  <span>{item.DailyProfitReturn}</span>
                                </li>
                                <li>
                                  <label>Monthly Profit:</label>
                                  <span>{item.MonthlyProfit}</span>
                                </li>
                                <li>
                                  <label>Total Profit Return:</label>
                                  <span>{item.TotalProfitReturn}</span>
                                </li>
                                <li>
                                  <label>Product deliver:</label>
                                  <span>{item.ProductDelivery}</span>
                                </li>
                              </ul>
                              <div className="package-footer">
                                <NavLink>More Details</NavLink>
                                <div className="button button-bs">Buy Now</div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                        :
                        null

                    )}
                  </ul>
                </div>

                <div className="row align-items-end gy-3">
                  <div className="col-md-3">
                    <div className="icon-form-group">
                      <label>Transaction Pin</label>
                      <div className="icon-input m-0">
                        <input type="text" value={PicCode} placeholder='Transaction Pin' onChange={(e) => setPicCode(e.target.value)} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 offset-md-6 text-end">
                    <button className='button button-primary input-height input-radius button-smlg' disabled={SelectedPackage == null || ButtonLoading || PicCode.length < 1} onClick={handleSubmit}>
                      Buy Package {ButtonLoading ? '...' : ''}
                    </button>
                  </div>
                </div>
              </>

              :
              ''
          }

        </div>
      </div >
    </>
  )
}

export default FranchisePurchasePackage