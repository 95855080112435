import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";
import FranchiseLoginCheck from "./FranchiseLoginCheck";
import { NumberFormat } from "../Dashboard/General/Functions";
import { calculateColumnTotal } from "../Dashboard/General/Util";
import { Form } from "react-bootstrap";
import {
  ArrowLeftStartOnRectangleIcon,
  EnvelopeIcon,
  EyeIcon,
  EyeSlashIcon,
  UserCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/outline";

function AddMember(props) {
  const [loading, setLoading] = useState(false);
  const [CodeLoading, setCodeLoading] = useState(false);
  const [ResendLoading, setResendLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [Username, setUsername] = useState("");
  const [UserNameExist, setUserNameExist] = useState(null);
  const [ReferrralExist, setReferrralExist] = useState(null);
  const [Referrral, setReferrral] = useState("");
  const [RefEmail, setRefEmail] = useState(null);
  const [NextStep, setNextStep] = useState(false);
  const [EmailAddress, setEmailAddress] = useState('');

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  let navigate = useNavigate();
  let params = useParams();

  const [Password, setPassword] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    CheckReferrral();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);

      const data = {
        Full_Name: event.target.Full_Name.value,
        Email_Address: EmailAddress,
        User_Name: Username,
        Referrer_Email: event.target.Referrer_Email.value,
        Password: Password,
      };

      // console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Members/SignUp",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      // console.log(data);

      axios(api_config)
        .then(function (response) {
          // console.log(response.data);

          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setNextStep(true);

          } else if (response.data.status_message === "Invalid Access Key.") {
            navigate("/franchise/login");
          } else if (response.data.status_code == 0) {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Something went wrong..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };
  const CheckReferrral = (e) => {
    if (Referrral.length > 0) {
      const data = {
        ReferralName: Referrral,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Members/CheckValidReferral",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_message == "Valid Referral") {
            setReferrralExist(true);
          }
          else if (response.data.status_message === "Invalid Access Key.") {
            navigate("/franchise/login");
          } else {
            setReferrralExist(false);
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else if (Referrral.length == 0) {
      setReferrralExist(null);
    }
  };

  const checkUsername = () => {
    if (Username == " " || Username == "") {
      setUserNameExist(true);
      return false;
    }

    const data = {
      UserName: Username,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/CheckUserNameExists",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    console.log(data);
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // "User Does Not Exists"
          setUserNameExist(false);
        }
        else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/franchise/login");
        } else {
          // "User Name Exists"
          setUserNameExist(true);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const handleUsernameChange = (event) => {
    const value = event.target.value.slice(0, 13);
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
    setUsername(sanitizedValue);

    const formattedUsername =
      sanitizedValue.charAt(0).toUpperCase() +
      sanitizedValue.slice(1).toLowerCase();
    setUsername(formattedUsername);
  };
  const handleReferralChange = (event) => {
    const value = event.target.value.slice(0, 13);
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, ""); // Remove spaces
    setReferrral(sanitizedValue);
  };
  const ResendEmail = () => {
    const data = {

      UserName: Username,

    };

    //console.log(data);

    var api_config = {
      method: 'post',
      url: config.base_url + 'Members/SendSignUpEmail',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    //console.log(data);
    axios(api_config)
      .then(function (response) {

        if (response.data.status_code == 1) {
          toast.success('Email Verification Code Sent Successfully', {
            position: toast.POSITION.TOP_RIGHT
          });

          setIsButtonDisabled(true);
          setTimeout(() => {
            setIsButtonDisabled(false);
          }, 60000);
        }
        else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/franchise/login");
        }
        else {

          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }

        setLoading(false);


      })
      .catch(function (error) {
        setLoading(false);
        toast.error('Network Error..', {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  }

  const handleVerificationCode = (event) => {

    event.preventDefault();
    event.stopPropagation();



    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else {

      setCodeLoading(true);

      const data = {
        UserName: Username,
        SecurityCode: event.target.SecurityCode.value,

      };

      // console.log(data);

      var api_config = {
        method: 'post',
        url: config.base_url + 'Members/VerifyEmailAddress',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      // console.log(data);


      axios(api_config)
        .then(function (response) {

          // console.log(response.data);

          if (response.data.status_code == 1) {

            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
            setTimeout(() => {
              window.location.reload();
            }, 3000);

          }
          else if (response.data.status_message === "Invalid Access Key.") {
            navigate("/franchise/login");
          }
          else if (response.data.status_code == 0) {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          setCodeLoading(false);

        })
        .catch(function (error) {
          setCodeLoading(false);
          toast.error('Something went wrong..', {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }

    setValidated(true);
  }

  return (
    <>
      <FranchiseLoginCheck />
      <ToastContainer />
      <div className="admin-content">
        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Add Member
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="button-row justify-content-end">

              <NavLink to="/franchise/dashboard">
                <button type="button" className="button button-outline-bs">
                  Back
                </button>
              </NavLink>
              <NavLink to="/franchise/add-kyc">
                <button type="button" className="button button-primary">
                  Add Kyc
                </button>
              </NavLink>
            </div>
          </div>
        </div>


        <div className="card card-table mb-3 mt-4">
          <div className="card-inner">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="row align-items-end">
                <div className="col-md-4">
                  <div className="icon-form-group">
                    <label htmlFor="">Full Name</label>
                    <div className="icon-input">
                      <input
                        name="Full_Name"
                        placeholder="Full Name"
                        className=""
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="icon-form-group">
                    <label htmlFor="">Username <span className="text-primary" style={{ fontSize: '10px' }}>(Minimum 8 to 13 characters)</span></label>
                    <div className="icon-input">
                      <input
                        name="User_Name"
                        type="text"
                        placeholder="Username"
                        maxLength={13}
                        required
                        onChange={(e) => handleUsernameChange(e)}
                        onBlur={checkUsername}
                        value={Username}
                      />
                      <div className="icon">
                        {UserNameExist == true ? (
                          <>
                            <XCircleIcon />
                          </>
                        ) : UserNameExist == false ? (
                          <>
                            <CheckCircleIcon />
                          </>
                        ) : (
                          <UserCircleIcon />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="icon-form-group">
                    <label htmlFor="">Email</label>
                    <div className="icon-input">
                      <input
                        type="email"
                        placeholder="Email"
                        onChange={(e) => setEmailAddress(e.target.value)}
                        value={EmailAddress}
                        className=""
                        required
                      />
                      <div className="icon">
                        <EnvelopeIcon />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  {RefEmail == null ? (
                    <div className="icon-form-group">
                      <label htmlFor="">Referral</label>
                      <div className="icon-input">
                        <input
                          type="text"
                          name="Referrer_Email"
                          value={Referrral}
                          onBlur={CheckReferrral}
                          onChange={(e) => handleReferralChange(e)}
                          placeholder="Referral"
                        />
                        <div className="icon">
                          {ReferrralExist == true ? (
                            <CheckCircleIcon />
                          ) : ReferrralExist == false ? (
                            <XCircleIcon />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="icon-form-group">
                      <label htmlFor="">Full Name</label>
                      <div className="icon-input">
                        <input
                          type="text"
                          className="disabled"
                          name="Referrer_Email"
                          value={RefEmail}
                          placeholder="Referral"
                          required
                          disabled
                        />
                        <div className="icon">
                          {ReferrralExist == true ? (
                            <CheckCircleIcon />
                          ) : ReferrralExist == false ? (
                            <XCircleIcon />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-4">
                  <div className="icon-form-group">
                    <label htmlFor="">Password</label>
                    <div className="icon-input">
                      <input
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        name={Password}
                        placeholder="Password"
                        className=""
                        required
                      />
                      <div className="icon" onClick={togglePasswordVisibility}>
                        {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-end">
                  <button
                    type="submit"
                    className="button button-primary button-icon-bs input-height input-radius mb-3"
                    disabled={loading}
                  >
                    Register {loading ? "..." : ""}{" "}
                    <span>
                      <ArrowLeftStartOnRectangleIcon />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>


        {NextStep ?

          <>
            <p className="my-3 d-flex m-auto justify-content-center">Check your inbox, spam or junk folder at <span className="text-primary ms-1"> {EmailAddress}</span></p>
            <div className="row">
              <div className="col-md-6 offset-md-3">


                <div className="card card-table">
                  <div className="card-inner">

                    <Form noValidate validated={validated} onSubmit={handleVerificationCode}>
                      <div className="row align-items-end">
                        <div className="col-md-8">
                          <div className="icon-form-group">
                            <label htmlFor="">Enter verification code</label>
                            <div className="icon-input">
                              <input
                                name="SecurityCode"
                                placeholder='Enter verification code'
                                className=""
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 text-end">
                          <button
                            type="submit"
                            className="button button-primary button-icon-bs input-height input-radius mb-3 w-100"
                            disabled={CodeLoading}
                          >
                            Verify Code {CodeLoading ? "..." : ""}{" "}
                            <span>
                              <CheckBadgeIcon />
                            </span>
                          </button>
                        </div>
                        <div className='my-3 d-flex m-auto justify-content-center'>
                          <p className='m-0 fs-6'>Did not received code?
                            <NavLink className='fs-6'
                              onClick={ResendEmail}
                              disabled={isButtonDisabled}
                            > RESEND</NavLink></p>
                        </div>
                      </div>
                    </Form>


                  </div>
                </div>
              </div>
            </div>
          </>

          :
          ""
        }
      </div >
    </>
  );
}

export default AddMember;
