import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PDFViewer, Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import AgreementImg from '../../../assets/images/partner/stamp_paper.png';
import stampImg from '../../../assets/images/partner/mag_stemp_png.png';
import { useNavigate } from 'react-router-dom';
import Config from '../../../Config';
import { toast } from 'react-toastify';


export const NumberFormat = (value) => {
    if (value == null) {
        return "";
    }
    else {
        const formattedNumber = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value);

        return formattedNumber;
    }

};

function Agreement_AppleWatchPdf(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const Full_Name = useState(localStorage.getItem("Full_Name"));
    const [loading, setLoading] = useState(false);
    const [AgreementData, setAgreementData] = useState([]);
    const [Kyc, setKyc] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const urlParams = new URLSearchParams(window.location.search);

        const PackageID = urlParams.get("PackageID");
        const SecretID = urlParams.get("SecretID");

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: PackageID,
            SecretID: SecretID,
        };

        // console.log(data);
        var api_config = {
            method: "post",
            url: Config.base_url + "Package/GetPackagebySecretID",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        console.log(data);

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code === 1) {
                    console.log(response.data);
                    setAgreementData(response.data.PackagePurchase);
                    setKyc(response.data.KYC);
                    setLoading(false);
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Request Parameters")
                ) {
                    navigate("/login");
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, []);


    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#FFFDEA'
        },
        section: {
            margin: 10,
            padding: 10,
            flexDirection: 'column',
            marginTop: 0,
            marginBottom: 20
        },
        header: {
            padding: 20,
            paddingBottom: 0,
        },

        title: {
            fontSize: '23pt',
            fontWeight: 'bold',
            textAlign: 'center',
            marginVertical: '10pt',
        },

        bold: {
            fontSize: '11pt',
            fontWeight: 'extrabold',
        },

        text: {
            fontSize: '11pt',
            fontWeight: 'normal',
            textAlign:'justify'
           
        },
        para: {
            marginBottom: '2pt',
            display: 'flex',
            flexDirection:'row',
            flexWrap: 'wrap'
        },
        textcenter: {
            fontSize: '10pt',
            fontWeight: 'normal',
            textAlign: 'center',
            paddingTop: '10pt',
            paddingBottom: '10pt',
        },
        ol: {
            padding: '15pt',
            paddingHorizontal: '5pt',
        },
        li: {
            fontSize: '11pt',
            fontWeight: 'normal',
            textAlign:'justify',
            marginBottom:'2pt'
        },
        top_footer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginVertical: '10pt',
            gap: '10pt',
            width: '100%',
            marginTop: '30pt'
        },
        bottom_footer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginVertical: '10pt',
            gap: '10pt',
            width: '100%',
        },
        left: {
            width: '48%',
            position: 'relative'
        },
        right: {
            width: '48%',
            position: 'relative'
        },
        lable: {
            fontSize: '10pt',
            fontWeight: 'medium',
            marginBottom: '5pt'
        },
        line: {
            borderBottom: '1px',
            borderColor: '#000',
            fontSize: '10pt',
            fontWeight: 'bold',
            paddingBottom: '5pt'
        },
        notext: {
            borderBottom: '1px',
            borderColor: '#000',
            fontSize: '10pt',
            fontWeight: 'bold',
            paddingBottom: '5pt',
            height: '20pt'
        },
        stamp: {
            position: 'absolute',
            top: '-40pt',
            right: '25pt',
            width: '120pt',
        },

    });


    const MyDocument = () => (
        <Document>
            <Page size="LEGAL" style={styles.page} orientation="portrait" wrap>
                <View style={styles.header}>
                    <Image
                        src={AgreementImg}
                        style={{ width: '100%', height: 'auto' }}
                    />
                </View>
                <View style={styles.section}>
                    <View style={styles.main_Heading}><Text style={styles.title}>Purchase Agreement</Text></View>
                    <View style={styles.para}>
                        <Text style={styles.text}>This Purchase Agreement is made at Islamabad on this</Text>
                        <Text style={styles.bold}>{moment(AgreementData?.BuyingDate).format(Config.date_format)}</Text>
                        <Text style={styles.text}> by and Between </Text>
                        <Text style={styles.bold}> MAG Ventures ( SMC-PVT ) LTD, Corporate
                            Unique Identification No. ( 0250862 )</Text>
                        <Text style={styles.text}> having Address,</Text>
                        <Text style={styles.bold}>"Lower Ground
                            Floor, Plaza No. 49, Spring North Commercial, Phase 7, Bahria
                            Town, Rawalpindi."</Text>
                        <Text style={styles.text}> (hereinafter called the</Text>
                        <Text style={styles.bold}>"Owner/ Seller"</Text>
                        <Text style={styles.text}> )of the one part.</Text>

                    </View>
                    <View style={styles.para}>
                        <Text style={styles.textcenter}>
                            AND
                        </Text>
                    </View>
                    <View style={styles.para}>
                        <Text style={styles.text}>
                            <Text style={styles.bold}>{Full_Name}</Text> {Kyc.RelationName} <Text style={styles.bold}>{Kyc.FatherName}</Text>
                            {Kyc.DocumentType == "License" ? (
                                <>
                                    License No. <Text style={styles.bold}>{Kyc.LicenseNumber}</Text>
                                </>
                            ) : Kyc.DocumentType === "ID" ? (
                                <>
                                    CNIC No. <Text style={styles.bold}>{Kyc.IDCardNumber}</Text>
                                </>
                            ) : Kyc.DocumentType === "Passport" ? (
                                <>
                                    Passport No. <Text style={styles.bold}>{Kyc.PassportNumber}</Text>
                                </>
                            ) : (
                                <></>
                            )} Resident of <Text style={styles.bold}>{Kyc.Address} </Text>
                            (hereinafter called the <Text style={styles.bold}>"Purchaser/ Stakeholder"</Text> ) of the other part.
                        </Text>
                    </View>

                    <View style={styles.para}>
                        <Text style={styles.text}>
                            The objectives of this Agreement are to encourage expansion and
                            diversification of trade between the Parties;
                        </Text>
                    </View>
                    <View style={styles.ol}>
                        <Text style={styles.li}>
                            1. The Stakeholder agrees to purchase Apple Watch (SE) for <Text style={styles.bold}>PKR 1
                                lac (1,00,000).</Text>
                        </Text>
                        <Text style={styles.li}>
                            2. This purchase agreement is dated{" "}
                            <Text style={styles.bold}> {moment(AgreementData?.BuyingDate).format(Config.date_format)}{" "}</Text>
                            and the product delivery date is{" "}
                            <Text style={styles.bold}> {moment(AgreementData?.ClosingDate).format(Config.date_format)}.</Text>

                        </Text>
                        <Text style={styles.li}>

                            3. The Stakeholder agrees to pay the specified purchase amount for
                            the chosen product that is <Text style={styles.bold}>Apple Watch (SE).</Text>

                        </Text>
                        <Text style={styles.li}>

                            4. The Company will generate revenue through <Text style={styles.bold}>dropshipping</Text>;
                            different businesses of Mag Ventures and through the wholesale
                            and retail trade of <Text style={styles.bold}>Apple Products</Text>, specifically <Text style={styles.bold}>Mobile phones </Text>
                            in the <Text style={styles.bold}>Pakistan market</Text>, and will pay the profit to the
                            Stakeholder on the <Text style={styles.bold}>1st date of every month,</Text> as per the time
                            commitment specified for the <Text style={styles.bold}>chosen product.</Text> Profit distribution
                            shall commence from the <Text style={styles.bold}>first month</Text> following the payment date.

                        </Text>
                        <Text style={styles.li}>

                            5. The Company will be responsible to deliver the product,i.e.
                            <Text style={styles.bold}> Apple Watch (SE)</Text> on time and in case of any damage the company
                            will recover it.

                        </Text>
                        <Text style={styles.li}>

                            6. Both Parties agree not to breach this contract under any
                            circumstances. This agreement is <Text style={styles.bold}>governed by the laws of
                                Pakistan.</Text> Any disputes arising out of or in connection with this
                            agreement shall be settled through arbitration in accordance
                            with the rules of the Pakistan Arbitration Association. This
                            agreement constitutes the entire understanding between the
                            Parties and supersedes all prior agreements, negotiations, and
                            discussions. Amendments to this agreement shall be valid only if
                            made in writing and signed by both Parties. This agreement shall
                            be binding upon and inure to the benefit of the Parties and
                            their respective successors and assigns.
                        </Text>
                    </View>
                    <View style={styles.para}>
                        <Text style={styles.text}>
                            This agreement constitutes the entire agreement between the parties with respect to the subject matter hereof, and supersedes all pre-existing agreements and understandings between them with respect thereto.
                        </Text>
                    </View>
                    <View style={styles.para}>
                        <Text style={styles.text}>
                            This Agreement creates a joint venture relationship between the Company and the User.
                        </Text>
                    </View>
                    <View style={styles.para}>
                        <Text style={styles.text}>
                            In witness whereof, the Parties have executed this agreement as of the date first above written
                        </Text>
                    </View>
                    <View style={styles.top_footer}>
                        <View style={styles.left}>
                            <Text style={styles.lable}>
                                Name of Stakeholder
                            </Text>
                            <Text style={styles.line}>
                                {Full_Name}
                            </Text>
                        </View>
                        <View style={styles.right}>
                            <Text style={styles.lable}>
                                Name of Company Representative
                            </Text>
                            <Text style={styles.line}>
                                Syed Mohsin Sultan (CEO)
                            </Text>
                        </View>
                    </View>
                    <View style={styles.bottom_footer}>
                        <View style={styles.left}>
                            <Text style={styles.lable}>
                                Signature of Stakeholder
                            </Text>
                            <Text style={styles.notext}>

                            </Text>
                        </View>
                        <View style={styles.right}>
                            <Text style={styles.lable}>
                                Signature of Company
                            </Text>
                            <Text style={styles.notext}>

                            </Text>
                            <Image
                                src={stampImg}
                                style={styles.stamp}
                            />
                        </View>
                    </View>
                </View>
            </Page >
        </Document >
    );

    return (
        <>
            <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
                {
                    loading ?
                        "Loading..."
                        :
                        <PDFViewer width="100%" height="100%" fileName="sample.pdf">
                            <MyDocument />
                        </PDFViewer>
                }

            </div>
        </>
    );
}

export default Agreement_AppleWatchPdf