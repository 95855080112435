import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Config from "../../../Config";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import EmailVerificationLogin from "./EmailVerificationLogin";
import sendEmail from "../../Common/SendEmail";
import LoginCheck from "../General/LoginCheck";

// import SendEmail from "../../../Common/SendEmail";

function ChangePassword(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));


    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // passwords
    const [typeOldPassword, setTypeOldPassword] = useState(false);
    const [typeNewPassword, setTypeNewPassword] = useState(false);
    const [typeConfPassword, setTypeConfPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [currnetpasword, setcurrnetpasword] = useState("");
    const [ConfirmPasword, setConfirmPasword] = useState("");
    const [AuthCode, setAuthCode] = useState("");

    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isPasswordValid, setPasswordValid] = useState(true);


    const currnetpassword = (e) => {
        let value = e.target.value
        setcurrnetpasword(value)
    }

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {



            if (password != confirmPassword) {
                toast.error('New password and Confirm password should be same', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                setLoading(true);
                const data = {
                    UserID: UserID,
                    AccessKey: AccessKey,
                    OldPassword: event.target.OldPassword.value,
                    NewPassword: password,
                    AuthorizationCode: AuthCode,

                };

                var api_config = {
                    method: 'post',
                    url: Config.base_url + 'Members/ChangePassword',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };



                axios(api_config)
                    .then(function (response) {

                        // console.log(response.data);
                        if (response.data.status_code == 1) {
                            toast.success('Password updated successfully', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            window.location.reload();
                        }
                        else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                        setLoading(false);
                    })
                    .catch(function (error) {

                        toast.error('Network Error..', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            }
        }

        setValidated(true);
    };

    const [timer, setTimer] = useState(60); // 60 seconds
    const handleSendCode = () => {
        const props = {
            TransactionType: 'ChangePassword'
        };

        sendEmail(props);

        setIsButtonDisabled(true);

        // Enable the button after 1 minute
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 60000);
        // Start the timer countdown
        setTimer(60);
    }

    useEffect(() => {
        let interval;

        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isButtonDisabled]);


    const handleChange = (event) => {
        const value = event.target.value;
        setPassword(value.slice(0, 18));

        if (validatePassword(value)) {
            setPasswordValid(true);
        } else {
            setPasswordValid(false);
        }
    };

    const isUppercaseLetterPresent = (password) => {
        const uppercaseRegex = /[A-Z]/;
        return uppercaseRegex.test(password);
    };

    const isNumberPresent = (password) => {
        const numberRegex = /\d/;
        return numberRegex.test(password);
    };

    const validatePassword = (password) => {
        const passwordRegex =
            /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
        return passwordRegex.test(password);
    };

    return (


        <>
            <LoginCheck />
            <ToastContainer />
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <ul className="profile-info-list">
                    <li>
                        <div className="icon-form-group">
                            <label htmlFor="">Enter Verification Code</label>
                            <div className="icon-input">
                                <input placeholder="Enter Code"
                                    type="number"
                                    onChange={(e) => {
                                        if (e.target.value.length <= 6) {
                                            setAuthCode(e.target.value);
                                        }
                                    }}
                                    value={AuthCode}
                                    required
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <button type="button"
                            onClick={handleSendCode}
                            className="button button-outline-bs input-height"
                            disabled={isButtonDisabled}><span>Get Code</span>
                        </button>
                    </li>
                    <li>
                        <div className="icon-form-group">
                            <label htmlFor="">Current Password</label>
                            <div className="icon-input">
                                <input placeholder="Enter Password" maxLength={18}
                                    onChange={currnetpassword}
                                    type={typeOldPassword ? "text" : "password"} className="form-control" name="OldPassword" required
                                    autoComplete="off"
                                />
                                <div className="icon" onClick={(e) => {
                                    setTypeOldPassword(!typeOldPassword);
                                }}>
                                    {
                                        typeOldPassword ?
                                            <EyeSlashIcon />
                                            :
                                            <EyeIcon />
                                    }
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="icon-form-group">
                            <label htmlFor="">New Password</label>
                            <div className="icon-input">
                                <input placeholder="Enter New Password"
                                    type={typeNewPassword ? "text" : "password"}
                                    onChange={handleChange}
                                    value={password}
                                    className="form-control"
                                    name="NewPassword"
                                    required />
                                <div className="icon" onClick={(e) => {
                                    setTypeNewPassword(!typeNewPassword);
                                }}>
                                    {
                                        typeNewPassword ?
                                            <EyeSlashIcon />
                                            :
                                            <EyeIcon />
                                    }
                                </div>
                            </div>
                        </div>
                    </li>

                    {/* Password Validation Messages */}
                    {password && !isPasswordValid && (
                        <li>
                            <ul className="password-validation-message">
                                <li className={password.length >= 8 && password.length <= 18 ? "completed" : "uncompleted"}>
                                    Minimum 8 to 18 characters
                                </li>
                                <li className={isUppercaseLetterPresent(password) ? "completed" : "uncompleted"}>
                                    At least one uppercase letter
                                </li>
                                <li className={isNumberPresent(password) ? "completed" : "uncompleted"}>
                                    At least one number
                                </li>
                                <li className={/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password) ? "completed" : "uncompleted"}>
                                    At least one special character
                                </li>
                            </ul>
                        </li>
                    )}
                    <li>
                        {/* Confirm Password Input */}
                        <div className="icon-input">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                value={confirmPassword}
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                className=""
                                required
                            />
                            <div className="icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                {showConfirmPassword ? <EyeSlashIcon /> : <EyeIcon />}
                            </div>
                        </div>
                        <button className="button button-bs input-height"
                            disabled={password.length === 0 || currnetpasword.length === 0 || confirmPassword.length === 0 || AuthCode.length === 0}
                        >Save  {loading ? '...' : ''}</button>
                    </li>
                </ul>
            </Form>
        </>
    );
}

export default ChangePassword;